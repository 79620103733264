<template>
  <div>
    <!--    <div class="content-header no-bg-01" v-if="!$root.gamePlayFullscreen">-->
    <!--      <div class="title-1">-->
    <!--        <router-link to="/"><img src="../assets/back_arrow.svg"/></router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="gamePlayFullscreen">
      <iframe
        id="xoshPlayId"
        title="Xosh Play"
        :width="width"
        :height="height"
        allowfullscreen="true"
        :src="link"
      >
      </iframe>
    </div>
    <div class="modal-background" id="regModal">
      <div class="modal-content">
        <span class="close" @click="hideModal('regModal')"></span>
        <h4>Sorry!</h4>
        <h5>{{ msg }}</h5>
      </div>
    </div>
    <div class="modal-background" id="regModal2">
      <div class="modal-content pop-02">
        <span class="close" @click="hideModal('regModal2')"></span>
        <h4>Please login to play game</h4>

        <router-link to="/registration" tag="button" class="submit-btn5"
          >Okay
        </router-link>
        <router-link to="/" tag="button" class="submit-btn6"
          >Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "../router/config";
import axios from "axios";

export default {
  name: "GamePlay",
  data: () => {
    return {
      phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : "",
      host: null,
      link: null,
      msg: null,
      width: 0,
      height: 0,
      gamePlayFullscreen: false
    };
  },
  created() {
    // `${config.ip}:${config.port}`
    this.host = this.$root.url();
    this.startGame();
  },
  methods: {
    showModal: function(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    startGame() {
      let vm = this;
      // if (!localStorage.getItem("phone")) {
      //   setTimeout(function() {
      //     vm.showModal("regModal2");
      //   }, 100);
      //   return false;
      // } else {
      const path = "userCoinInfo";
      const url = `${this.host}/${path}?phone=${this.phone}&gameId=${this.$route.params.gameId}`;
      //console.log(url);
      axios
        .get(url)
        .then(response => {
          console.log(response.data);
          if (response.data.success === true && response.data.isPlayGame) {
            this.link = response.data.link;
            //console.log("iframe", this.iframeOn);
            this.gamePlayFullscreen = true;
            this.width = window.innerWidth;
            this.height = window.innerHeight;
          } else {
            let code = response.data.code;
            if (code === 3004) {
              localStorage.clear();
              vm.$router.push({ name: "Registration" });
            }
            this.msg =
              code === 505
                ? "Invalid Game"
                : "Insufficient credit to play this game";
            this.showModal("regModal");
            setTimeout(function() {
              vm.$router.push({ name: "PurchaseCoin" });
            }, 2000);
            //$("#startGame").show();
          }
        })
        .catch(error => {
          console.log(error);
        });
      //  }
    }
  }
};
</script>

<style scoped></style>
